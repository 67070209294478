import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import { FaCheck } from "react-icons/fa";
import Columns from "../components/columns";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    serwis: file(relativePath: { eq: "sp-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    laptop: file(relativePath: { eq: "zarzadzanie-uslugami.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const ServiceManagement = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Service management"
      headline="Automate your company's processes"
    >
      <SEO
        title="Service management - dedicated and ready-to-use systems for services"
        description="Service management, service systems ✅ A service management solution dedicated to and complementing ERP and a ready system with many possibilities"
        keywords={["service management"]}
      />

      <p>
        As a producer of IT solutions, we specialize in creating business
        products. For a long time our clients have been{" "}
        <strong>
          smaller and larger services companies from various industries
        </strong>
        . Together with them we have developed service systems, which we can
        safely call proven and{" "}
        <strong>the most flexible systems we know</strong>.
      </p>
      <br />
      <br />
      <Columns>
        <div>
          <h3>Dedicated tool for service management</h3>
          <p>
            <strong>Aura Business</strong> is our largest solution, which is
            designed for large companies or those that operate in niche
            industries and have non-standard needs. The system{" "}
            <strong>focuses on flexibility and fit</strong>. It is not only a
            tool supporting service management.{" "}
            <strong>Aura Business also supports sales and marketing</strong>.
            However, we know that our customers may want to use only some of the
            modules we offer or completely new ones, which is why{" "}
            <strong>
              it is the customer who decides on the final shape of the solution
            </strong>
            . Thanks to this it is always ideally suited to the needs of a given
            business.
          </p>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
          <a href="https://aurabusiness.com/">
            <GatsbyImage
              image={data.aura.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 300,
              }}
              alt="Aura Business"
            />
          </a>
          <Container>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <a className={styles.startNow} href="https://aurabusiness.com/">
                Learn more about Aura Business
              </a>
            </div>
          </Container>
        </div>
      </Columns>

      <br />
      <br />

      <br />
      <br />

      <Columns>
        <div>
          <h3>
            {" "}
            Ready-to-use system with many possibilities - Serwis&nbsp;Planner{" "}
          </h3>
          <p>
            <strong>Serwis Planner</strong> is a ready-to-use tool supporting
            service management. This does not mean, however, that it cannot be
            properly configured depending on the specifics of the functioning of
            a given company.{" "}
            <strong>The system works well in many industries</strong>. It
            doesn't matter if you manage a services company or production orders
            -{" "}
            <strong>
              the flexibility of the system will allow it to be tailored to the
              needs of your business
            </strong>
            , thus increasing the convenience of use and the effectiveness of
            your operations.
          </p>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
          <a href="https://serwisplanner.pl/">
            <GatsbyImage
              image={data.serwis.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 300,
              }}
              alt="SerwisPlanner"
            />
          </a>
          <Container>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <a className={styles.startNow} href="https://serwisplanner.pl/">
                Learn more about Serwis Planner
              </a>
            </div>
          </Container>
        </div>
      </Columns>
      <br />
      <br />
      <center>
        <h4>
          Are you interested in our company management systems? Contact us. We'd
          be happy to talk about the development opportunities for your
          business.
        </h4>
        <Container>
          <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/contact/">
              Contact us
            </Link>
          </div>
        </Container>
      </center>
    </FunctionPageLayout>
  );
};

export default ServiceManagement;
